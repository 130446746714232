import React, {useState, useEffect, useRef} from 'react';
import logo from './logo.svg';
import './App.css';
import './flags.css';
import {
    Backdrop,
    Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, MenuItem, Select, TextField,
} from "@mui/material";

import {Header} from "./components/header";
import UnityWrapper from "./unity";
import './utils/common'

import {
    useAddRewardMutation,
    useCancelNotificationMutation,
    useCheckTokenMutation, useDeleteInfoMutation, useGetUserQuery,
    useSaveTokenMutation,
    useSendMsgMutation, useSetOldMutation, useUpdateUserMutation
} from "./store/api/api-requests";

import {getMessaging, getToken} from "firebase/messaging";

import {getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import {useLiveQuery} from "dexie-react-hooks";
import {initializeApp} from "firebase/app";
import {useDispatch, useSelector} from "react-redux";
import {LanguageProvider, useLanguage} from "./context/LanguageContext";
import translations from "./utils/translations";

import AppStoreImg from "./img/app-store-badge.png"
import AmazonStoreImg from "./img/amazon-store-badge.png"
import GoogleStoreImg from "./img/google-play-badge.png"
import Notifications from "./components/notifications";
import * as Sentry from "@sentry/react";
import {Footer} from "./components/Footer";
import config from "./config";
import WebglUnsupported from "./components/WebglUnsupported";
import StartGame from "./components/StartGame";


const firebaseConfig = {
    apiKey: "AIzaSyA4SwWpW-GwUltuhZQeIYSMk1nsN2wy9Pk",
    authDomain: "cm-test-853b4.firebaseapp.com",
    projectId: "cm-test-853b4",
    storageBucket: "cm-test-853b4.appspot.com",
    messagingSenderId: "1064677070576",
    appId: "1:1064677070576:web:29c6c1ebff53a123769435",
    measurementId: "G-G0TD9K6JK7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = 'Notification' in window ? getMessaging() : null;


function App() {
    const urlParams = new URLSearchParams(window.location.search);
    const jwtData = window.getParsedXsollaJwt()

    const dispatch = useDispatch()
    const { language, changeLanguage, getT } = useLanguage();

    const lastSymbol = parseInt(window.generatedUserUuid[window.generatedUserUuid.length - 1], 10);
    const abMode = urlParams.get('mode')==null?((isNaN(lastSymbol) || lastSymbol > 5) ? 'b' : 'a'):urlParams.get('mode');


    const initialBuild = urlParams.get('build') || localStorage.getItem('build') || false;
    const initialCheats = urlParams.get('cheats') || localStorage.getItem('cheats') || '0'; // Defaults to '0' if nothing is set

    window.isTokenFetching = false;

    const isMobile = ((navigator.userAgent.match("Mac") || navigator.userAgent.match("Linux")) &&
        navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && navigator.maxTouchPoints !== 256)

    const lastPermissionRef = useRef((localStorage.getItem('lastNotificationPermission') == null && (typeof Notification !='undefined')) ? Notification.permission : localStorage.getItem('lastNotificationPermission'));


    const [buildNumber, setBuildNumber] = useState(urlParams.get('build') || null);
    const [unityVersion, setUnityVersion] = useState(null);

    const [isPwaInstalled, setIsPwaInstalled] = useState(localStorage.getItem("isPwaInstall")=="true"||null)
    const [promptStarted, setPromptStarted] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);


    const [iB, setiB] = useState(initialBuild);
    const [iCheat, setiCheat] = useState(initialCheats);

    const [mode, setMode] = useState(abMode);

    const [tempToken, setTempToken] = useState(false)
    const [user, setUser] = useState(false)
    const [tempUser, setTempUser] = useState(false)
    const [token, setToken] = useState(localStorage.getItem('token')==undefined?'':localStorage.getItem('token'))
    const [openDialog, setOpenDialog] = useState({
        cookie: false,
        notification: false
    });
    const [confirmN, setConfirmN] = useState(false);
    const [play, setPlay] = useState(false);
    const [tokenValidChecked, setTokenValidChecked] = useState(false);


    const userInfo = useGetUserQuery({
        xsolla_id:jwtData!=null&&jwtData.sub!=undefined ? jwtData.sub:null,
        generated_id: window.generatedUserUuid,
        pwa_installed: !(localStorage.getItem("isPwaInstall")=='false'),
        notification_enabled: (typeof Notification != 'undefined') && Notification?.permission == 'granted',
        email: jwtData!=null&&jwtData.email!=undefined ? jwtData.email : null
    }, {skip: window.generatedUserUuid == null || window.generatedUserUuid == false|| window.generatedUserUuid == 'null' });


    const [updateUser, updateUserResponse] = useUpdateUserMutation()
    const [setOld, setOldResponse] = useSetOldMutation()
    const [deleteInfo, deleteInfoResponse] = useDeleteInfoMutation()
    const [addReward, addRewardResponse] = useAddRewardMutation()
    const [saveToken, saveTokenResponse] = useSaveTokenMutation()
    const [checkToken, getCheckToken] = useCheckTokenMutation()
    const [sendMsg, sendMsgResponse] = useSendMsgMutation()
    const [cancelNotifications, cancelNotificationsResponse] = useCancelNotificationMutation()


    const getAbMode = (id) => {
        const lastSymbol = parseInt(id[id.length - 1], 10);
        return ((isNaN(lastSymbol) || lastSymbol > 5) ? 'b' : 'a')
    }

    useEffect(() => {

        let userId = window.initApp()
        setTempUser(userId)
//debugger
        window.postAnalyticsEvent("startedAbtestWebGL", userId, "#is#mode="+getAbMode(userId)+'#is#test_id=20250212_AB_WEBGL_V', false);

    },[])

    const prevInstallStateRef = useRef(localStorage.getItem('isPwaInstall')||'false');

    useEffect(() => {
        //
        // const checkIsPwaInstalled = () => {
        //
        //     if(localStorage.getItem("isPwaInstall") == null) {
        //         debugger
        //         if(isPwaInstalled === true)
        //             window.postAnalyticsEvent("pwaInstalled", window.userID, "", true);
        //         else if(isPwaInstalled === false)
        //             window.postAnalyticsEvent("pwaDeleted", window.userID, "", true);
        //
        //     }
        //
        //     if(isPwaInstalled != null)
        //         localStorage.setItem("isPwaInstall", isPwaInstalled==null?null:isPwaInstalled.toString())
        //
        //     // Сравниваем с предыдущим состоянием для определения изменений
        //     if (prevInstallStateRef.current !== null) {
        //         debugger
        //
        //         // PWA была удалена
        //         if (prevInstallStateRef.current === 'true' && isPwaInstalled===false) {
        //             window.postAnalyticsEvent("pwaDeleted", window.userID, "", true);
        //         }
        //         // PWA была установлена
        //         else if (prevInstallStateRef.current === 'false' && isPwaInstalled===true) {
        //             window.postAnalyticsEvent("pwaInstalled", window.userID, "", true);
        //         }
        //     }
        //
        //     if(isPwaInstalled!=null)
        //         prevInstallStateRef.current = isPwaInstalled.toString();
        // };
        //
        // // Устанавливаем интервал проверки
        // //const timeout = setTimeout(checkIsPwaInstalled, 20000); // проверяем каждые 10 секунд
        // const interval = setInterval(checkIsPwaInstalled, 10000); // проверяем через 10 секунд
        //
        // // Очищаем при размонтировании
        // return () => {
        //     clearInterval(interval);
        // };
    }, [])


    const handleDialogClose = () => {
        setDialogOpen(false);
    };


    const installHandler =  () => {
        console.log('pwaWindowOpen');
        if(promptStarted) {
            setDialogOpen(true);
            return false;
        }
        setPromptStarted(true);

        window.postAnalyticsEvent("pwaWindowOpen", window.userID, "", false);

        if (window.installPromptEvent === undefined) {
            console.log("installPromptEvent undefined")
            console.log(window.installPromptEvent)
            return;
        }
        const result = window.installPromptEvent.prompt();
        console.log('result');
        console.log(result);
        window.installPromptEvent.userChoice.then((choiceResult) => {

            if (choiceResult.outcome === 'accepted') {
                console.log('accepted PWA');
                window.isPwaInstalled = true
                setIsPwaInstalled(true)
                localStorage.setItem("isPwaInstall", "true")

                window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=1", false);
                window.postAnalyticsEvent("pwaInstalled", window.userID, "", true);

            } else {
                console.log('declined PWA');
                window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=0", false);
                localStorage.setItem("isPwaInstall", "false")

            }

            setPromptStarted(false);

            window.installPromptEvent = null;
        });

    }

    useEffect(() => {
        const fetchBuildNumber = async () => {
            const url = config.REACT_APP_VERSION_URL+'/buildNumber.js?t='+Date.now()

            try {
                const response = await fetch(url);
                const text = await response.text();
                const match = text.match(/var unityBuildNumber\s*=\s*(\d+)/);

                console.log('>>> get buildNumber ' + url)
                if (match && match[1]) {
                    setBuildNumber(Number(match[1]));
                    window.unityBuildNumber = Number(match[1])
                    console.log('>>Build number: ', Number(match[1]))
                } else {
                    console.error('unityBuildNumber not found');

                    Sentry.captureMessage(
                        `unityBuildNumber not found \n${url}`,
                        "Error"
                    )
                }
            } catch (error) {
                console.error('Error fetching build number:', error);
                //default
                setBuildNumber(6274);
                window.unityBuildNumber = 6274


                Sentry.captureMessage(
                    `unityBuildNumber not found, use default \n${url}`,
                    "Error"
                )
            }
        };
        const fetchVersion = async () => {
            const url = config.REACT_APP_VERSION_URL+'/version.js?t='+Date.now()

            try {
                const response = await fetch(url);
                const text = await response.text();
                const match = text.match(/var unityVersion\s*=\s*"([^"]+)"/);

                console.log('>>> get version ' + url)

                if (match && match[1]) {
                    setUnityVersion((match[1]));
                    window.unityVersion = (match[1])
                    console.log('>>UnityVersion: ', (match[1]))

                } else {
                    console.error('unityVersion not found');

                    Sentry.captureMessage(
                        `unityVersion not found, use default \n${url}`,
                        "Error"
                    )
                }
            } catch (error) {
                console.error('Error fetching unityVersion:', error);
                setUnityVersion('80.0.0');
                window.unityVersion = '80.0.0'


                Sentry.captureMessage(
                    `unityVersion not found, use default \n${url}`,
                    "Error"
                )
            }
        };
        if(!buildNumber)
            fetchBuildNumber();
        if(!unityVersion)
            fetchVersion();


    }, []);

    useEffect(() => {
        if(buildNumber&&unityVersion) {
            sendBuildNumber();
        }
    }, [unityVersion, buildNumber])



    const handleCancelAllScheduledNotifications = (event) => {
        cancelNotifications({
            user_id: window.gameParams.user_id
        })
    };

    const handleUnityChangeLanguage = (event) => {
        console.log('handleUnityChangeLanguage');
        console.log(event.detail);
        changeLanguage(event.detail.value)
    };
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleNotifEvent = async (event) => {

        const delayTime = 5000 + (Math.random() * 500) + 500; // 5 seconds + random 0.5-1 second
        await delay(delayTime);

        console.log(new Date());
        console.log('handleNotifEvent ', event);

        sendMsg({
            title: event.detail.title,
            body: event.detail.description,
            date: event.detail.time,
            event_id: event.detail.eventID,
            user_id: window.gameParams.user_id,
            browser: getBrowserName(),
            timezone: new Date().getTimezoneOffset()
        });
    };

    useEffect(() => {

        window.addEventListener('cancelAllScheduledNotifications', handleCancelAllScheduledNotifications);
        window.addEventListener('changeLocalization', handleUnityChangeLanguage);
        window.addEventListener('requestNotificationPermission', showNotificationPermissionDialog);//askNotificationPermission
        window.addEventListener('notifEvent', handleNotifEvent);

        return () => {

            window.removeEventListener('cancelAllScheduledNotifications', handleCancelAllScheduledNotifications);
            window.removeEventListener('changeLocalization', handleUnityChangeLanguage);
            window.removeEventListener('requestNotificationPermission', showNotificationPermissionDialog);
            window.removeEventListener('notifEvent', handleNotifEvent);

        };
    }, []);



    const getBrowserName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.includes("Firefox")) {
            return "Firefox";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            return "Opera";
        } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Chrome";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Safari";
        } else if (userAgent.includes("Edg")) {
            return "Edge";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            return "Internet Explorer";
        } else {
            return "unknown";
        }
    }

    const requestForToken = async () => {
        if (window.isTokenFetching) return false;
        window.isTokenFetching = true

        console.log("requestForToken>> start")
        // const storedToken = localStorage.getItem('token');
        // if (storedToken) {
        //     setToken(storedToken);
        //     return storedToken;
        // }

        try {
            console.log("requestForToken>> try")

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/play/firebase-messaging-sw.js?13', { scope: '/play/'})
                    .then(async function (registration) {
                        const currentToken = await getToken(messaging, {
                            vapidKey: 'BLX3LM5yo1SXgSuVa3BDruQlfqXTLQSENxDD4HVzzLsZDeGmK_6DwjP2nVLpR5EskX8z2i3v7NnaQaDhe16nYiQ',
                            serviceWorkerRegistration: registration
                        })
                        console.log('requestForToken>> ');
                        console.log(currentToken);

                        if (currentToken) {
                            console.log('current token for client: ', currentToken);
                            setToken(currentToken)
                            setTempToken(currentToken)
                            setTokenValidChecked(true)
                            saveToken({
                                token: currentToken,
                                user_id: window.gameParams.user_id,
                                browser: getBrowserName()
                            })
                            localStorage.setItem('token', currentToken)
                            window.isTokenFetching = false
                            return currentToken
                        } else {
                            console.log('requestForToken>> No registration token available. Request permission to generate one.');
                            return false;
                        }

                    }).catch(function (err) {
                    console.log('requestForToken>> Service Worker registration failed:', err);
                })
            } else {
                console.log("requestForToken>> error")

            }

        } catch (err) {
            console.log('An error occurred while retrieving token. ', err);
            throw err; // Rethrow the error if needed
        }
    };

    const handleCheckCurrentToken = async (token) => {
        try {
            const result = await checkToken({
                user_id: window.gameParams.user_id,
                browser: getBrowserName(),
                token: token,
            });
            console.log('Token checked:', result);
            setTokenValidChecked(true);
            return result;
        } catch (error) {
            console.error('Error during token check:', error);
        }
    }

    const handlePermissionPromise = (permission) => {

        if (permission === 'granted') {
            console.log('start token request')
            window.postAnalyticsEvent("notificationEnable", window.userID, "", false);

            requestForToken().then()
        } else {
            window.postAnalyticsEvent("notificationDisable", window.userID, "", false);
        }

    }
    useEffect(() => {
        if(localStorage.getItem("registration") == "init" && jwtData != null && jwtData.hasOwnProperty('sub')) {
            localStorage.setItem("registration", "reward")
        }
    })


    useEffect(() => {
        const checkNotificationPermission = () => {
            try {
                console.log('checkNotificationPermission')

                const currentPermission = Notification.permission;
                const lastPermission = lastPermissionRef.current

                console.log(currentPermission)
                console.log(lastPermission)

                // Сохраняем текущее разрешение как последнее проверенное
                localStorage.setItem('lastNotificationPermission', currentPermission);
                lastPermissionRef.current = currentPermission.toString()

                // Если разрешение изменилось с 'granted' на 'denied'
                if ((lastPermission === 'granted' || lastPermission == 'default') && (currentPermission === 'denied' || currentPermission === 'default')) {

                    // Отправляем аналитическое событие
                    window.postAnalyticsEvent("notificationDisable", window.userID, "", false);
                }
                if((lastPermission == 'denied' && currentPermission == 'granted') ||
                    (lastPermission == 'default' && currentPermission == 'granted') ) {
                    console.log('start token request 2')
                    window.postAnalyticsEvent("notificationEnable", window.userID, "", false);

                    requestForToken().then()
                }
                if (tokenValidChecked === false && currentPermission == 'granted') {
                    setTokenValidChecked(true);
                    if (token === null || token === undefined || token === '') {
                        requestForToken().then()
                    } else {
                      handleCheckCurrentToken(token).then(result => {
                          if (!result.data.valid) {
                              console.log(new Date() + " " + "checked token");
                              console.log(result.data.valid);
                              requestForToken().then();
                          }
                      }).catch(function (err) {
                          console.log('check token >> failed:', err);
                      });
                    }

                }
            } catch (e) {

            }

        };

        // Устанавливаем интервал для регулярной проверки
        if(typeof Notification != 'undefined') {
            const interval = setInterval(checkNotificationPermission, 5000); // проверяем каждые 5 секунд
            return () => clearInterval(interval);

        }

    }, [tokenValidChecked]);


    const showNotificationPermissionDialog = () => {
        console.log('showNotificationPermissionDialog')
        if(Notification.permission == 'default') askNotificationPermission()
        else  setOpenDialog({...openDialog, notification: true}); // Open dialog if permission is default
    }

    const askNotificationPermission = () => {
        localStorage.setItem('lastNotificationPermission', Notification.permission);

        if(Notification.permission == 'granted') return false;

        if (!("Notification" in window)) {
            console.error("This browser does not support notifications.");
        } else {
            console.log("start request N")
            if (Promise) {
                Notification.requestPermission().then((permission) => {
                    console.log(('request perm PROMISE'))

                    handlePermissionPromise(permission)
                }).catch((e) => {
                    console.log(('request perm PROMISE err'))

                });
            } else {
                Notification.requestPermission(function(permission) {
                    console.log(('request perm'))
                    handlePermissionPromise(permission)
                });
            }
        }
    }


    useEffect(() => {
        console.log("use eff confirm N")
        console.log(confirmN)
        if(confirmN == true) {
            //askNotificationPermission()
            //requestPermissionsPopup()
        }
    }, [confirmN])



    const sendNotification = () => {
        if (Notification.permission === "granted") {
            navigator.serviceWorker.getRegistration().then(function(reg) {
                reg.showNotification('Hello')
            }).catch((e) => {

            });
        }
    }

    const sendBuildNumber = () => {
        setUserProperties(analytics, {
            build_number: buildNumber,
            version_number: unityVersion

        });
    }


    useEffect(() => {
        if(userInfo.isLoading == false && userInfo.data!=undefined) {
            const id = userInfo.data.new_user ? userInfo.data.user_id : userInfo.data.xsolla_id
            window.userID = id
            window.gameParams.user_id = id
            setUser(id)
            if(userInfo.data.reward == true) {
                localStorage.setItem("registration","complete")
            }

            window.__TEST__userInfoLoaded = 1
            window.postAnalyticsEvent("pageLoadComplete", window.gameParams.user_id, "", false);

        }
        if(userInfo.status == "rejected") {
            let fallback_id = window.generatedUserUuid
            if(jwtData != null && jwtData.sub!=undefined) {
                fallback_id = jwtData.sub
            }
            window.userID = fallback_id
            window.gameParams.user_id = fallback_id
            setUser(fallback_id)

            window.__TEST__userInfoLoaded = JSON.stringify({
                userInfo: userInfo,
                request: config.REACT_APP_API_URL
            })

            Sentry.captureMessage(
                `get_user error \n${userInfo}`,
                "Error"
            )
        }
    },[userInfo.isLoading])



    useEffect(() => {
        if(userInfo.isLoading == false && userInfo.data!=undefined) {

            let isStandalone = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
            if (isStandalone) {


                setIsPwaInstalled(true)
                localStorage.setItem("isPwaInstall", "true")

                updateUser({
                    generated_id: window.generatedUserUuid,
                    xsolla_id: jwtData!=null&&jwtData.sub!=undefined ? jwtData.sub:null,
                    pwa_installed: true,
                    notification_enabled: Notification?.permission == 'granted'
                })

                window.postAnalyticsEvent("pwaLogin", window.generatedUserUuid, "");
                window.postAnalyticsEvent("pwaInstalled", window.userID, "", true);
            } else {
                setIsPwaInstalled(false)
            }
        }

        const handleBeforeInstallPrompt = (event) => {
            console.log('event beforeinstallprompt triggered')
            console.log(event)
            event.preventDefault();
            window.installPromptEvent = event;
            window.installPrompt = true
            window.isPwaInstalled = false
            setIsPwaInstalled(false)

            if(localStorage.getItem("isPwaInstall") == "true") {
                window.postAnalyticsEvent("pwaDelete", window.userID, "", true);
            }

            localStorage.setItem("isPwaInstall", "false")

            updateUser({
                generated_id: window.generatedUserUuid,
                xsolla_id: jwtData!=null&&jwtData.sub!=undefined ? jwtData.sub:null,
                pwa_installed: !(localStorage.getItem("isPwaInstall")=='false'),
                notification_enabled: Notification?.permission == 'granted'
            })
        }


        window.addEventListener("pwaInstallAccepted", function() {
            setIsPwaInstalled(true)
        });


        if ('onbeforeinstallprompt' in window) {
            window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        } else {
            console.log('beforeinstallprompt not supported on this browser.');
        }

        return () => {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };


    }, [userInfo.isLoading])

    useEffect(() => {

        //if((jwtData == null || !jwtData.hasOwnProperty("sub"))) {
        if (buildNumber && unityVersion && userInfo.data!=undefined &&
            userInfo.isLoading==false &&
            (userInfo.data.xsolla_id != null || userInfo.data.xsolla_id != 'null')) {
            if(abMode!='b'&&(jwtData==null|| !jwtData.hasOwnProperty("sub"))) {
                window.showXsollaLogin()
                window.postAnalyticsEvent("loginWindow", window.gameParams.user_id, "#is#generatedUserUuid="+window.generatedUserUuid+'#is#userClicked=false', false);

            }
        }

    }, [unityVersion, buildNumber, userInfo ])


    const getContent = () => {
        if((!buildNumber || !unityVersion || userInfo.isLoading || user == false)) {
            return <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={(!buildNumber || !unityVersion || userInfo.isLoading || user == false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            return  <div className="loader-container">
                <CircularProgress  color="inherit" />
            </div>
        }

        if(!isMobile) {
            if((play||(user!=false&&user!=undefined
                && userInfo.data!=undefined
                && (userInfo.data.xsolla_id != null)
                && (userInfo.data.xsolla_id != undefined)
                && abMode=='a')||abMode=='b')) {
                return  <UnityWrapper addReward={addReward} userInfo={userInfo} buildNumber={buildNumber} jwtData={jwtData} />
            } else {
                return <StartGame jwtData={jwtData} setPlay={setPlay} />
            }
        }

        return <WebglUnsupported />
    }

    return (
        <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>

        {/*{(!userInfo.isError&&!userInfo.isLoading&&userInfo.data!=undefined)&&<div>*/}
        {/*        {userInfo.data.new_user?"New":"Old"} | XsollaId: {userInfo.data.xsolla_id} | StateId: {userInfo.data.user_id}*/}
        {/*        <Button onClick={() => {*/}
        {/*            setOld({xsolla_id: userInfo.data.xsolla_id, new_user: false})*/}

        {/*        }}>Mark XSOLLA as Old</Button>*/}
        {/*        |*/}
        {/*        <Button onClick={() => {*/}
        {/*            setOld({xsolla_id: userInfo.data.xsolla_id, new_user: true})*/}

        {/*        }}>Mark XSOLLA as NEW</Button>*/}
        {/*        |*/}
        {/*        <Button onClick={() => {*/}
        {/*            deleteInfo({user_id: userInfo.data.user_id})*/}
        {/*        }}>Delete info</Button>*/}

        {/*        <Notifications user_id={userInfo.data.user_id} xsolla_id={userInfo.data.xsolla_id} />*/}

        {/*    </div>}*/}

            <div className={`App ${isMobile ? 'mobile-bg' : 'cm-bg'}`}>
                <Header
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    handleDialogClose={handleDialogClose}
                    isPwaInstalled={isPwaInstalled}
                    installHandler={installHandler}
                    jwtData={jwtData}
                />

                {getContent()}
            </div>


            <Footer
                language={language}
                getT={getT}
                userId={window.userID}
                buildType={urlParams.get('buildType')}
            />

            <Dialog
                open={openDialog.notification}
                onClose={() => {
                    setOpenDialog({...openDialog, notification: false})

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {getT("show_notification_title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div dangerouslySetInnerHTML={{__html:getT("show_notification_description")}}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog({...openDialog, notification: false})}>{getT("no_close")}</Button>
                    <Button  target="_blank" href={getT('show_instructions_link')} onClick={() => { setOpenDialog({...openDialog, notification: false}); }} autoFocus>
                        {getT("show_instructions")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Sentry.ErrorBoundary>
    );
}

export default App;
